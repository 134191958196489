@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,900&display=swap');

body {
  color: #001F54!important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif!important;
}

a {
  color: #29A8BE!important;
}

.App {
  text-align: center;
  background-color: #11001c!important;
  font-family: 'lato';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar {
  background-color: rgba(255,255,255,0.7);
  padding-top: 0.15rem!important;
  padding-bottom: 0.15rem!important;
}

.navbar-nav {
  text-align: right;
  padding: 15px;
}

.vh-100-d {
  height: 100vh!important;
}

.text-neon {
  text-shadow: #09e3e8 3px 3px 30px;
  font-family: 'lato';
}

.black-font {
  font-weight: 900;
}

.black-italic {
  font-weight: 900;
  font-style: italic;
}

.mt-50 {
  margin-top: 50px!important;
}
.mt-100 {
  margin-top: 100px!important;
}
.mt-150 {
  margin-top: 150px!important;
}

.center {
  text-align: center!important;
}

.bold {
  font-weight: 600!important;
}

.black-text {
  color: #000!important;
}

.crop-text {
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  width:100px;
}

.hwga-title {
  font-family: 'embossedBlackWide';
  color: white!important;
}

.nft-title {
  font-family: 'impact';
}

.btn-join {
  color: #ffffff!important;
  box-shadow: #ee9b00 3px 3px 25px;
  background: linear-gradient(135deg, #e9d8a6 0%, #bb3e03 100%);
  border: none;
  position: relative;
  padding: 12px 20px;
  border-radius: 999px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.mqea-title-color {
  font-family: 'lato';
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-image: linear-gradient(
-45deg, #bb3e03, #e9d8a6, #ee9b00, #f72585);
    will-change: background, opacity, filter;
    background-size: 600%;
    animation: _mp-title-color 16s linear infinite;
}

.mqea-service-text {
  animation-duration: 1000ms;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: 'Lato';
  min-height: 150px;
}

@keyframes _mp-title-color{
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}

.hwga.carousel-caption {
  width: 50%;
  position: absolute;
  top: 40%!important;
  left: 5%;
  right: auto;
  bottom: auto;
}

.btn-minter-amount {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.btn-minter {
  text-transform: none;
  background: #ff7f7f;
  color: #fff;
  font-family: "Roboto";
  letter-spacing: 1px;
  padding: 16px 34px;
  transition: all 0.2s;
  border-radius: 48px;
  font-size: 16px;
  font-weight: 500;
  border: none;
}

.btn-minter-color {
  transition: background 120ms ease 0s, opacity 120ms ease 0s, filter 120ms ease 0s, transform 300ms ease 0s;
  background-image: linear-gradient(
    -45deg, #fcba04, #bf0603, #ffea00, #bd1e1e);
  will-change: background, opacity, filter;
  background-size: 600%;
  animation: _btn-minter-color 16s linear infinite;
  box-shadow: 0 6px 20px 0 rgba(255, 204, 188, 0.5);
  position: relative;
}

@keyframes _btn-minter-color {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}

.rarity-rare-color {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-image: linear-gradient(
-45deg, #38b000, #ccff33);
    will-change: background, opacity, filter;
    background-size: 150%;
    animation: _mp-title-color 3s linear infinite;
}

.rarity-epic-color {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-image: linear-gradient(
-45deg, #f72585, #3a0ca3);
    will-change: background, opacity, filter;
    background-size: 150%;
    animation: _mp-title-color 3s linear infinite;
}

.rarity-legendary-color {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  background-image: linear-gradient(
-45deg, #f15bb5, #fee440, #00bbf9, #00f5d4);
    will-change: background, opacity, filter;
    background-size: 150%;
    animation: _mp-title-color 3s linear infinite;
}

.moon-color {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  text-shadow: #fff 1px 1px 10px ;
  background-image: linear-gradient(
-45deg, #00ff1e, #fff, #39bb71, #2fef1a);
    will-change: background, opacity, filter;
    background-size: 400%;
    animation: _mp-title-color 6s linear infinite;
}

.roadmap-title {
  padding: 20px;
  background-color: #610274;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 600;
  background-image: linear-gradient( -45deg, #ff07ff, #2C0045);
  will-change: background, opacity, filter;
  background-size: 150%;
  animation: _mp-title-color 6s linear infinite;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.myScore {
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
  margin: 15px;
  padding: 15px;
}

/* .center{
  display: block;
  text-align: center;
  margin: 20px auto;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

.white-text {
  color: #fff;
}

.text-center {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .vh-100-d {
    height: 100%!important;
  }
}